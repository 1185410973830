module.exports = {
  siteMetadata: {
    title: `Gamily`,
    description: `Website for Gamily`,
    author: `@chrish-d`,
  },
  plugins: [
    `gatsby-plugin-styled-components`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: 'MO4csJcrGTwFRhDN3Tpr5gtt',
        homeSlug: `home`,
        version: process.env.NODE_ENV === `production` ? `published` : `draft`,
        includeLinks: true,
        resolveLinks: true,
      },
    },
  ],
};
